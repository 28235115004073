import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {version} from '../../package.json';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private swUpdate: SwUpdate
  ) {
    this.initializeApp();
  }

  initializeApp() {
    console.log(`------------------------------------`);
    console.log(`🚀🚀 App Version - ${version} 🚀🚀`);
    console.log(`------------------------------------`);

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    // Subscribe to the Service Work app version updates
    if (this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('A new version of the app is available. Load it?'))
          window.location.reload();
      });
    }
  }
}

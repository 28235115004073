import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RestProvider } from './providers/rest/rest';
import { DataProvider } from './providers/data/data';
import { GlobalVariable } from './providers/global/global';
import { PermissionsProvider } from './providers/permissions/permissions';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';
import { AuthService } from './providers/auth/auth.service';
import { DataliveHttpInterceptor } from './providers/http/http-intercept';
import { MediaProvider } from './providers/media/media';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { SignaturePadModule } from './components/custom/signature-pad/signature-pad.module';
import { IonicStorageModule } from '@ionic/storage';
import {Camera} from '@ionic-native/camera/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { AlertServiceService } from './providers/alert/alert-service.service';
import { ComponentsModule } from './components/components.module';
import { DefectAddPageModule } from './pages/dashboard/defect-add/defect-add.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import { ModalVehicleDefectsComponent } from './pages/dashboard/defect-add/modal/modal-vehicle-defects/modal-vehicle-defects.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent, ModalVehicleDefectsComponent],
  entryComponents: [ModalVehicleDefectsComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    DashboardPageModule,
    SignaturePadModule,
    ComponentsModule,
    IonicSelectableModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    RestProvider,
    DataProvider,
    GlobalVariable,
    AuthService,
    MediaProvider,
    PermissionsProvider,
    Camera,
    AlertServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataliveHttpInterceptor,
      multi: true
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
